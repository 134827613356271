import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  FormErrorMessage,
  Box,
  Select,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useEffect } from "react";

const eventValidationSchema = Yup.object({
  start: Yup.date().required("Start date and time are required."),
  end: Yup.date().required("End date and time are required."),
  repeat: Yup.string().required("This field is required."),
});

const getFormattedDateTime = (date) => {
  let dateObj = date instanceof Date ? date : new Date(date); // Convert string to Date object if needed
  const localDate = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, 16);
};

const getFormattedDateTimeSelectedSlot = (date) => {
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, 16);
};

// Example options for 'session name' and 'session location'
const serviceNameOptions = [
  { value: "123456789011", label: "Any", id: 0 },
  { value: "Teaching", label: "Teaching" },
  { value: "Feedback", label: "Feedback" },
  // ... other session names
];

const sessionOrBreakOptions = [
  { value: "session", label: "Session" },
  { value: "break", label: "Break" },
];

const sessionLocationOptions = [
  { value: "Home Studio", label: "Home Studio" },
  { value: "Zoom Meeting", label: "Zoom Meeting" },
  // ... other session locations
];

const repeatEventOptions = [
  { value: "No Repeat", label: "No Repeat" },
  { value: "Every Week", label: "Every Week" },
  { value: "Every Fortnight", label: "Every Fortnight" },
  { value: "Every Week Day", label: "Every Week Day" },
  { value: "Every day", label: "Every day" },
];
const EventModal = ({
  isOpen,
  onClose,
  onSubmit,
  selectedSlot,
  event,
  onDelete,
  onUpdate,
  events,
  services,
  view
}) => {
  const toast = useToast();
  const [isUpdate, setIsUpdate] = useState(false);
  const defaultStart = selectedSlot && new Date(selectedSlot.start);
  const defaultEnd = selectedSlot && new Date(selectedSlot.end);

  useEffect(() => {
    event ? setIsUpdate(true) : setIsUpdate(false);
  },[event]);

  // then set:
  if (defaultStart && view != "week") {
    defaultStart.setHours(8, 0); // Set hours to 8 AM with 0 minutes
  }

  if (defaultEnd && view != "week") {
    defaultEnd.setHours(10, 0); // Set hours to 10 AM with 0 minutes
  }
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(eventValidationSchema),
    defaultValues: {
      sessionOrBreak: event ? event?.sessionOrBreak : sessionOrBreakOptions[0]?.value,
      serviceId: event ? event?.serviceId?._id : services[0]?._id,
      sessionLocation: event
        ? event.sessionLocation
        : sessionLocationOptions[0].value,
      start: event
        ? getFormattedDateTime(event.start)
        : selectedSlot
        ? getFormattedDateTimeSelectedSlot(defaultStart)
        : "",
      end: event
        ? getFormattedDateTime(event.end)
        : selectedSlot
        ? getFormattedDateTimeSelectedSlot(defaultEnd)
        : "",
      repeat: event?.repeat || "No Repeat",
    },
  });

  const handleStartChange = (value) => {
    // Calculate the new end time, which is 2 hours ahead of the start time
    const startDate = new Date(value);
    const endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours
  
    const pad = (num) => num.toString().padStart(2, '0');
    const formattedEndDate = `${endDate.getFullYear()}-${pad(endDate.getMonth() + 1)}-${pad(endDate.getDate())}T${pad(endDate.getHours())}:${pad(endDate.getMinutes())}`;

    setValue("end", formattedEndDate);
  };
  

  const onFormSubmit = (data) => {
    const newEventStart = new Date(data.start).getTime();
    const newEventEnd = new Date(data.end).getTime();
    const isEventAlreadyPresent = events.some((event) => {
      const eventStart = new Date(event.start).getTime();
      const eventEnd = new Date(event.end).getTime();
      // return eventStart === newEventStart && eventEnd === newEventEnd;
      return (
        (newEventStart > newEventEnd) ||
        (newEventStart >= eventStart && newEventStart < eventEnd) || // New event starts within an existing event
        (newEventEnd > eventStart && newEventEnd <= eventEnd) ||     // New event ends within an existing event
        (newEventStart <= eventStart && newEventEnd >= eventEnd)     // New event completely surrounds an existing event
      );
    });
    if (!event && isEventAlreadyPresent) {
      toast({
        title: "Event already exists.",
        description:
          "An event with the same start and end time already exists.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    const startDate = new Date(data.start);
    const endDate = new Date(data.end);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    const hours = Math.floor(differenceInHours);
    const minutes = Math.round((differenceInHours - hours) * 60);
    const formattedTime =
      `${hours} ${hours === 1 ? "hr" : "hrs"}` +
      (minutes !== 0 ? ` ${minutes} min` : "");

    if(isUpdate) {
      data.id = event?._id;
      onUpdate({
        ...data,
        totalTime: formattedTime,
      }); 
      onClose();
      toast({
        title: "Event Updated.",
        description: `We've updated your new event. Duration: ${formattedTime}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }
    onSubmit({
      ...data,
      totalTime: formattedTime,
    });
    onClose();
    toast({
      title: "Event created.",
      description: `We've added your new event. Duration: ${formattedTime}`,
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
    reset();
  };

  const handleDelete = () => {
    if (event && onDelete) {
      onDelete(event?._id); 
    }
    onClose();
  };

  const handleUpdate = () => {
    if (event && onUpdate) {
      onUpdate(event); 
      setIsUpdate(true);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent
        bg="white"
        color="black"
        position="relative"
        py={{ base: "10px", xl: "40px" }}
      >
        <ModalCloseButton position="absolute" top="10px" left="10px" />
        <ModalHeader textAlign="center" fontSize="24px" fontWeight="600">
          {isUpdate ? "Edit Session" : "Create Session"}
        </ModalHeader>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <ModalBody px={{ base: "10px", xl: "80px" }} pb={6}>
          <FormControl isInvalid={errors.sessionType}>
          {/* <FormLabel>Session or Break</FormLabel>
            <Controller
              control={control}
              name="sessionOrBreak"
              render={({ field }) => (
                <Select
                  {...field}
                  borderWidth="1px"
                  borderColor="gray.300"
                  _hover={{
                    borderWidth: "1px",
                    borderColor: "gray.300",
                  }}
                >
                 {sessionOrBreakOptions.map((option) => (
                      <option
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                </Select>
              )}
            />
            <FormErrorMessage>{errors.sessionType?.message}</FormErrorMessage> */}
          <FormControl mt="4" isInvalid={errors.start}>
              <FormLabel>Start</FormLabel>
              <Controller
                name="start"
                control={control}
                rules={{
                  validate: value => {
                    const selectedTime = new Date(value).getHours();
                    const selectedDay = new Date(value).getDay();
              
                    // Load work hours from local storage
                    const workHours = JSON.parse(localStorage.getItem('workHours')) || { monFri: { start: 9, end: 17 }, sat: { start: 9, end: 17 }, sun: { start: 9, end: 17 } };
              
                    let workingHours;
                    if (selectedDay >= 1 && selectedDay <= 5) {
                      // It's a weekday
                      workingHours = workHours.monFri;
                    } else if (selectedDay === 6) {
                      // It's Saturday
                      workingHours = workHours.sat;
                    } else {
                      // It's Sunday
                      workingHours = workHours.sun;
                    }
              
                    if (selectedTime < workingHours.start || selectedTime > workingHours.end) {
                      return `Please select a time between ${workingHours.start}:00 and ${workingHours.end}:00`;
                    }
                    return true;
                  }
                }}
                render={({ field }) => (
                  <Input
                    type="datetime-local"
                    borderWidth="1px"
                    borderColor="gray.300"
                    _hover={{
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                    sx={{
                      "::-webkit-calendar-picker-indicator": {
                        filter: "invert(1)", // Inverts the colors of the calendar icon
                        backgroundColor: "transparent", // Avoids a white square around the icon
                      },
                    }}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleStartChange(e.target.value);
                    }}
                  />
                )}
              />
              <FormErrorMessage>{errors.start?.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.end}>
              <FormLabel>End</FormLabel>
              <Controller
                name="end"
                control={control}
                render={({ field }) => (
                  <Input
                    type="datetime-local"
                    borderWidth="1px"
                    borderColor="gray.300"
                    _hover={{
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                    sx={{
                      "::-webkit-calendar-picker-indicator": {
                        filter: "invert(1)", // Inverts the colors of the calendar icon
                        backgroundColor: "transparent", // Avoids a white square around the icon
                      },
                    }}
                    {...field}
                  />
                )}
              />
              <FormErrorMessage>{errors.end?.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt="4" isInvalid={errors.repeat}>
              <FormLabel>Repeat</FormLabel>
              <Controller
                control={control}
                name="repeat"
                render={({ field }) => (
                  <Select
                    {...field}
                    borderWidth="1px"
                    borderColor="gray.300"
                    _hover={{
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                  >
                    {repeatEventOptions.map((option) => (
                      <option
                        style={{ backgroundColor: "white", color: "black" }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <FormErrorMessage>{errors.repeat?.message}</FormErrorMessage>
            </FormControl>
          </FormControl>
            <FormControl mt="12" isInvalid={errors.serviceId}>
            <FormLabel>Specify a Service</FormLabel>
              <Controller
                control={control}
                name="serviceId"
                render={({ field }) => (
                  <Select
                    {...field}
                    borderWidth="1px"
                    borderColor="gray.300"
                    _hover={{
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                  >
                    {services.map((service) => (
                      <option
                        style={{
                          backgroundColor: "white",
                          color: "black",
                        }}
                        key={service?._id}
                        value={service?._id}
                      >
                        {service?.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <FormErrorMessage>{errors.serviceId?.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt="4" isInvalid={errors.sessionLocation}>
            <FormLabel>Specify a Location</FormLabel>
              <Controller
                control={control}
                name="sessionLocation"
                render={({ field }) => (
                  <Select
                    {...field}
                    borderWidth="1px"
                    borderColor="gray.300"
                    _hover={{
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                  >
                    {sessionLocationOptions.map((option) => (
                      <option
                        style={{ backgroundColor: "white", color: "black" }}
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <FormErrorMessage>
                {errors.sessionLocation?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt="4" isInvalid={errors.sessionRate}>
            <FormLabel>Specify the rate</FormLabel>
              <Controller
                control={control}
                name="sessionRate"
                render={({ field }) => (
                  <Input
                    type="number"
                    {...field}
                    borderWidth="1px"
                    borderColor="gray.300"
                    placeholder="Specify the rate"
                    _placeholder={{
                      color: "black"
                    }}
                    _hover={{
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                  >
                  </Input>
                )}
              />
              <FormErrorMessage>
                {errors.sessionRate?.message}
              </FormErrorMessage>
            </FormControl>

            
            {!event && (
              <Button
                type="submit"
                bg="black"
                color="white"
                fontWeight="bold"
                rounded="20px"
                w="full"
                mt="8"
                _hover={{
                  border: "1px solid black",
                  color: "black",
                  bg: "white",
                }}
              >
                Add Session to Calendar
              </Button>
            )}
            {event && (
              <Button
              type="submit"
              bg="black"
              color="white"
              fontWeight="bold"
              rounded="20px"
              w="full"
              mt="8"
              _hover={{
                border: "1px solid black",
                color: "black",
                bg: "white",
              }}
              >
                Save Event
              </Button>
            )}
            {event && (
              <Button
                bg="#E1526C"
                color="white"
                fontWeight="bold"
                rounded="20px"
                w="full"
                mt="4"
                onClick={handleDelete}
                _hover={{
                  color: "white",
                  bg: "#E1526C",
                }}
              >
                Delete Event
              </Button>
            )}
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EventModal;
