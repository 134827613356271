import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
import { FaLocationArrow, FaRegCalendar } from "react-icons/fa6";
import { SERVICE_IMAGE_URL, USER_IMAGE_URL } from "../../../../apiPaths";
import StdProfile from "../../../ProfileDetails";
export default function MyBookingList(data) {
  const { pathname } = useLocation();

  const booking = data?.data;  
  
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const startDate = new Date(booking?.sessionId?.start);
  const endDate = new Date(booking?.sessionId?.end);
  const formattedDate = `${
    startDate.getMonth() + 1
  }/${startDate.getDate()}/${startDate.getFullYear()}`;
  const startTime = startDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const endTime = endDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedTime = `${startTime} - ${endTime}`;
  const image = SERVICE_IMAGE_URL + booking?.serviceId?.image;
  const details = currentUser.roleName == "STUDENT" ? booking?.tutorId : booking?.userId; 
  const [showDetailModel, setShowDetailModel] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let buttonLabel;
  if (startDate < today) buttonLabel = "Completed"; 
  else if (startDate.getTime() === today.getTime()) buttonLabel = "Today"; 
  else buttonLabel = "Upcoming"; 

  function handleFormOpen(val) {    
    setShowDetailModel(val);
  }

  function handleFormClose(val) {
    setShowDetailModel(val);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        alignItems="center"
        w="100%"
        bg="white"
        rounded="8px"
        gap={{ base: "14px", lg: "24px" }}>
        <Box
          // py={{ base: "24px", "2xl": "20px" }}
          // borderRadius={{ base: "8px 8px 0px 0px", "2xl": "8px 0px 0px 8px" }}
          // px={{ base: "10px", "2xl": "10px" }}
          padding="16px"
          width={{ base: "100%", lg: "auto" }}
          color="white">
          <Image
            height="160px"
            width={{ base: "100%", md: "160px" }}
            borderRadius="4px"
            objectFit={"cover"}
            src={image}
            crossOrigin="anonymous"
            alt="Booking Image"
          />
        </Box>
        <Flex
          flex="1"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ base: "column", lg: "row" }}
          px={{ base: "0px", lg: "10px" }}
          gap={{ base: "10px", lg: "10px" }}>
          <Flex flexDirection="column" gap="4px">
            <Box fontSize="16px" fontWeight="600" paddingBottom="3px">
              {booking?.serviceId?.name}
            </Box>
            <Flex flexDirection="row" gap="8px" paddingBottom="8px">
              <Image
                height="24px"
                width="24px"
                borderRadius="50%"
                src={booking?.tutorId?.profilePic ? USER_IMAGE_URL + booking?.tutorId?.profilePic : "https://avatars2.githubusercontent.com/u/37842853?v=4"}
                crossOrigin="anonymous"
                alt="Profile Image"
                onClick={() => handleFormOpen(true)}
                cursor="pointer"
              />
              <Box fontSize="14px" fontWeight="600">
                {booking?.tutorId?.firstName} {booking?.tutorId?.lastName}
              </Box>
            </Flex>
            <Box marginLeft="4px">
              <Flex
                fontSize="14px"
                fontWeight="500"
                display="flex"
                alignItems="center"
                gap="13px"
                pb="3px">
                <FaRegCalendar fontSize={"15px"} /> {formattedDate}
              </Flex>
              <Flex
                fontSize="14px"
                fontWeight="500"
                display="flex"
                alignItems="center"
                gap="13px"
                pb="3px">
                <TimeIcon fontSize={"15px"} />
                {formattedTime}
              </Flex>
              <Flex
                fontSize="14px"
                fontWeight="500"
                display="flex"
                alignItems="center"
                gap="13px"
                pb="3px">
                <FaLocationArrow fontSize={"15px"} />
                {booking?.sessionId?.sessionLocation}
              </Flex>
            </Box>
          </Flex>
          <Flex flexDirection="column" gap="19px" alignItems="center">
            <Button
              bg="black"
              _hover={{
                bg: "black",
              }}
              cursor="default"
              color="#F2F2F2"
              rounded="40px"
              py="12px"
              px="40px"
              w="200px" 
              fontSize="14px"
              fontWeight="600"
              mb={{ base: "30px", "2xl": "0px" }}
            >
              {buttonLabel}
            </Button>
            <Link>
              <Button
                bg="selectbg"
                color="black"
                _hover={{
                  bg: "white",
                  border: "1px solid black",
                }}
                border="1px solid white"
                rounded="40px"
                py="12px"
                px="40px"
                w="200px" // Set the same fixed width
                fontSize="14px"
                fontWeight="600"
              >
                Modify
              </Button>
            </Link>
          </Flex>

        </Flex>
      </Flex>
    </>
  );
}
