import React, { useEffect, useState } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  Box,
  Textarea,
  FormLabel,
  Image,
  Flex,
  Select,
  FormErrorMessage,
  Text,
  useToast,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  FormHelperText,
  ModalBody,
  filter
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_BASE_URL } from "../../../apiPaths";
import { Link } from "react-router-dom";

const signupSchema = yup.object().shape({
  start: yup.date().required("Start time is required"),
  end: yup
    .date()
    .required("End time is required")
    .min(yup.ref("start"), "End time can't be before start time"),
  service: yup.string().required("Service is required"),
  location: yup.string().required("Location is required"),
});

const RequestForm = ({
  onFormClose,
  fetchMyRequest,
  isCalenderRequest,
  startDate,
  endDate,
  tutorId,
  requestId
}) => {
  const toast = useToast();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const { creative, end, start, location, service } = JSON.parse(localStorage.getItem('sessionRequestFormData')) || {};
  const [tutors, setTutors] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(true);
  const { isOpen, onClose } = useDisclosure();
  const [isUserDisabled, setIsUserDisabled] = useState(false);
  const [isTutorDisabled, setIsTutorDisabled] = useState(false);
  const [isLoginForm, setLoginForm] = useState(false);
  const [selectedTutorName, setSelectedTutorName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [showDefaultService, setShowDefaultService] = useState(false);

  const currentDateTime = new Date().toISOString().slice(0, 16);
  const Start = startDate ? new Date(startDate) : new Date(); 
  Start.setHours(12, 0, 0, 0); 

  const End = endDate ? new Date(endDate) : new Date();
  End.setHours(14, 0, 0, 0);

  const formatDateTimeLocal = (date) => {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().slice(0, 16);
  };

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(signupSchema),
    defaultValues: {
      start: formatDateTimeLocal(Start),
      end: formatDateTimeLocal(End),
      location: "Home Studio",
      service,
      creative
    },
  });

  const setFormValues = (data) => {
    setValue("start", formatDateTimeLocal(new Date(data.start)));
    setValue("end", formatDateTimeLocal(new Date(data.end)));
    setValue("location", data.sessionLocation || "Home Studio");
    if(currentUser.roleName == "TUTOR") {
      setValue('service', data.service._id);      
      handleTutorChange(data?.tutor?._id)
      setIsTutorDisabled(true); 
      setIsUserDisabled(true); 
      setSelectedUserName(data?.user?.firstName + " " + data?.user?.lastName);
    }
  };

  const sessionLocationOptions = [
    { value: "Home Studio", label: "Home Studio" },
    { value: "Zoom Meeting", label: "Zoom Meeting" },
    // ... other session locations
  ];

  function handleClose() {
    localStorage.removeItem('sessionRequestFormData');
    onFormClose(false);
  }

  const onSubmit = async (data) => {
    if (!data.creative && !data.student) {
      toast({
          title: "Creative or user must be selected.",
          status: "error",
          duration: 3000,
          isClosable: true,
      });
      return;
    }
    
    if(!currentUser) {
      data.url = window.location.pathname;;
      data.isRequestModelOpen = true;
      localStorage.setItem('sessionRequestFormData', JSON.stringify(data));
      setLoginForm(true);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/session-request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": currentUser.token,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        toast({
          title: "Request failed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error("Network response was not ok");
      } else {
        if (!isCalenderRequest) fetchMyRequest();
        toast({
          title: "Session request send successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleClose();
      }
    } catch (error) {
      console.error("There has been a problem with your operation:", error);
    } finally {
    }
  };

  const handleTutorChange = (tutorId) => {
    const selectedTutor = tutors.find((tutor) => tutor._id === tutorId);    
    if (selectedTutor) {
      setShowDefaultService(true);
      setServices(selectedTutor.services);
      setValue('service', selectedTutor.services[0]._id);
    } else {
      setServices([]);
    }
  };

  const handleStartChange = (value) => {
    // Calculate the new end time, which is 2 hours ahead of the start time
    const startDate = new Date(value);
    const endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours
  
    const pad = (num) => num.toString().padStart(2, '0');
    const formattedEndDate = `${endDate.getFullYear()}-${pad(endDate.getMonth() + 1)}-${pad(endDate.getDate())}T${pad(endDate.getHours())}:${pad(endDate.getMinutes())}`;

    setValue("end", formattedEndDate);
  };

  useEffect(() => {

    async function fetchSelectedRequest() {
      const response = await fetch(`${API_BASE_URL}/session-request/get-by-id/${requestId}`, {
        headers: {
          'x-access-token': currentUser.token 
        }
      });
      const request = await response.json();
      if (request && request.data) {        
        setFormValues(request.data);
      }     
    }

    async function fetchTutors() {
      try {
        const response = await fetch(`${API_BASE_URL}/user/tutors/get?q=`, {});
        const data = await response.json();

        const filteredTutors = tutorId
          ? data.data.filter((tutor) => tutor._id === tutorId)
          : data.data;

        setTutors(filteredTutors);          
        
        if (tutorId && filteredTutors.length > 0) {
          setTimeout(() => {  
            setServices(filteredTutors[0].services);
            if(!requestId) setValue('service', filteredTutors[0].services[0]._id);
          }, 2000);
          if(!requestId) {
            setValue('creative', tutorId);
            setSelectedTutorName(`${filteredTutors[0].firstName} ${filteredTutors[0].lastName}`);
            handleTutorChange(tutorId);
            setIsTutorDisabled(true); 
          }
        }
        if (requestId) fetchSelectedRequest();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    async function fetchStudents() {
      try {
        const response = await fetch(`${API_BASE_URL}/user/students/get`, {});
        const data = await response.json();
        
        setStudents(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }    

    fetchTutors();   
    
    if(currentUser) fetchStudents();
  }, [requestId]);

  return (
    <>
      <Box>
        <Modal
          sx={{ position: "fixed", left: "130px" }}
          blockScrollOnMount={false}
          isOpen={open}
          isCentered
          onClose={onClose}
          size={{
            base: "md",
            md: "xl",
            lg: "2xl",
            xl: "2xl",
            "2xl": "2xl",
          }}
        >
          <ModalContent bg={"#fff"} height={{ base: "auto", sm: "auto" }}>
            <ModalCloseButton
              position="absolute"
              top="10px"
              left="10px"
              color="black"
              onClick={() => {
                handleClose();
              }}
            />
            <ModalHeader
              textAlign="center"
              fontSize="24px"
              fontWeight="600"
              color="black"
              marginTop="20px"
            >
              {requestId ? "Modify" : "Create"} Session Request
            </ModalHeader>

            <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "20px" }}>
              {/* <FormControl mt="2" isInvalid={errors.creative}>
                <FormLabel color="black">Creative</FormLabel>
                <Controller
                  control={control}
                  name="creative"
                  render={({ field }) => (
                    <Select
                      {...field}
                      borderWidth="1px"
                      borderColor="gray.300"
                      color="black"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        handleTutorChange(e.target.value);
                        setIsUserDisabled(e.target.value !== "");
                      }}
                      disabled={isTutorDisabled}
                    >
                      {!tutorId && <option value="">Select Creative</option>}
                      {tutors?.map((tutor) => (
                        <option
                          style={{ backgroundColor: "white", color: "black" }}
                          key={tutor._id}
                          value={tutor._id}
                        >
                          {tutor.firstName} {tutor.lastName}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                <FormErrorMessage>{errors.creative?.message}</FormErrorMessage>
              </FormControl> */}

              <FormControl w="100%" mt="4" isInvalid={errors.creative}>
                <FormLabel color="black">Creative</FormLabel>
                <Controller
                  control={control}
                  name="creative"
                  render={({ field }) => (
                    <AutoComplete
                      openOnFocus
                      onSelectOption={(params) => {
                        const { value,label } = params.item;
                        field.onChange(value); // Update form value with selected ID
                        handleTutorChange(value); // Additional logic when an item is selected
                        setIsUserDisabled(value !== ""); // Disable user input if a value is selected
                        setSelectedTutorName(label);
                      }}
                    >
                      <AutoCompleteInput
                        variant="outlined"
                        color="black"
                        w="100%"
                        borderColor="gray.200"
                        backgroundColor="white"
                        borderWidth="1px"
                        placeholder="Select Creative"
                        disabled={isTutorDisabled}
                        _placeholder={{ color: "black" }}
                        value={selectedTutorName || field.value}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          field.onChange(newValue); 
                          handleTutorChange(newValue); 
                          newValue.length == 0 ? setIsUserDisabled(false) : setIsUserDisabled(true);
                          setSelectedTutorName(newValue); 
                        }}
                      />
                      <AutoCompleteList
                        bg="gray.100" // Grey background for the dropdown
                        borderColor="black"
                        pb="2"
                        pt="0"
                      >
                        {tutors?.map((tutor) => (
                          <AutoCompleteItem
                            key={`option-${tutor._id}`}
                            value={tutor._id} 
                            label={`${tutor.firstName} ${tutor.lastName}`} 
                            bg="white"
                            color="black"
                            mt="2"
                            _hover={{ bg: "gray.200" }}
                            isSelected={field.value === tutor._id}
                          >
                            {tutor.firstName} {tutor.lastName}
                          </AutoCompleteItem>
                        ))}
                      </AutoCompleteList>
                    </AutoComplete>
                  )}
                />
                <FormErrorMessage>{errors.creative?.message}</FormErrorMessage>
              </FormControl>

              
              {currentUser?.roleName == "TUTOR" && (
            <FormControl w="100%" mt='4'>
            <FormLabel color="black">User</FormLabel>
              <Controller control={control}
                  name="student"
                  render={({ field }) => (
                  <AutoComplete 
                    openOnFocus
                    onSelectOption={(params) => {
                      const { value,label } = params.item;
                      field.onChange(value); 
                      handleTutorChange(currentUser?.id); 
                      setIsTutorDisabled(value !== ""); 
                      setSelectedUserName(label);
                    }}
                  >
                  <AutoCompleteInput
                    variant="outlined"
                    color="black"
                    w="100%"
                    borderColor="gray.200"
                    backgroundColor="white"
                    borderWidth="1px"
                    placeholder="Select User"
                    disabled={isUserDisabled}
                    _placeholder={{ color: "black" }} 
                    value={selectedUserName || field.value}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      field.onChange(newValue); 
                      newValue.length == 0 ? setIsTutorDisabled(false) : setIsTutorDisabled(true);
                      setSelectedUserName(newValue);
                    }}
                    
                  />
                  <AutoCompleteList
                    bg="gray.100" // Grey background for the dropdown
                    borderColor="black"
                    pb="2"
                    pt="0"
                  >
                    {students?.map((student) => (
                      <AutoCompleteItem
                        key={`option-${student?._id}`}
                        label={`${student.firstName} ${student.lastName}`}
                        value={`${student._id}`}
                        bg="white"
                        color="black"
                        mt="2"
                        _hover={{ bg: "gray.200" }}
                      >
                        {student?.firstName} {student?.lastName}
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>
                </AutoComplete>
              )}
            />
          </FormControl>
          )}
          
              <FormControl mt="4" isInvalid={errors.start}>
                <FormLabel color="black">Start</FormLabel>
                <Controller
                  name="start"
                  control={control}
                  rules={{
                    validate: (value) => {
                      const selectedTime = new Date(value).getHours();
                      const selectedDay = new Date(value).getDay();

                      // Load work hours from local storage
                      const workHours = JSON.parse(
                        localStorage.getItem("workHours")
                      ) || {
                        monFri: { start: 9, end: 17 },
                        sat: { start: 9, end: 17 },
                        sun: { start: 9, end: 17 },
                      };

                      let workingHours;
                      if (selectedDay >= 1 && selectedDay <= 5) {
                        // It's a weekday
                        workingHours = workHours.monFri;
                      } else if (selectedDay === 6) {
                        // It's Saturday
                        workingHours = workHours.sat;
                      } else {
                        // It's Sunday
                        workingHours = workHours.sun;
                      }

                      if (
                        selectedTime < workingHours.start ||
                        selectedTime > workingHours.end
                      ) {
                        return `Please select a time between ${workingHours.start}:00 and ${workingHours.end}:00`;
                      }
                      return true;
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="datetime-local"
                      borderWidth="1px"
                      borderColor="gray.300"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      color="black"
                      sx={{
                        "::-webkit-calendar-picker-indicator": {
                          filter: "invert(1)", // Inverts the colors of the calendar icon
                          backgroundColor: "transparent", // Avoids a white square around the icon
                        },
                      }}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        handleStartChange(e.target.value);
                      }}
                      min={currentDateTime}
                    />
                  )}
                />
                <FormErrorMessage>{errors.start?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mt="4" isInvalid={errors.end}>
                <FormLabel color="black">End</FormLabel>
                <Controller
                  name="end"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="datetime-local"
                      borderWidth="1px"
                      borderColor="gray.300"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      color="black"
                      sx={{
                        "::-webkit-calendar-picker-indicator": {
                          filter: "invert(1)", // Inverts the colors of the calendar icon
                          backgroundColor: "transparent", // Avoids a white square around the icon
                        },
                      }}
                      {...field}
                      min={currentDateTime}
                    />
                  )}
                />
                <FormErrorMessage>{errors.end?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mt="4" isInvalid={errors.service}>
                <FormLabel color="black">Specify a Service</FormLabel>
                <Controller
                  control={control}
                  name="service"
                  render={({ field }) => (
                    <Select
                      {...field}
                      borderWidth="1px"
                      borderColor="gray.300"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      color="black"
                    >
                     { !showDefaultService && (
                        <option style={{ display: 'block' }} value="">
                          Select Service
                        </option>
                      )}
                      {services?.map((service) => (
                        <option
                        style={{ backgroundColor: "white", color: "black" }}
                        key={service._id}
                        value={service._id}
                      >
                        {service.name}
                      </option>
                      ))}
                    </Select>
                  )}
                />
                <FormErrorMessage>{errors.service?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mt="4" isInvalid={errors.location}>
                <FormLabel color="black">Specify a Location</FormLabel>
                <Controller
                  control={control}
                  name="location"
                  render={({ field }) => (
                    <Select
                      {...field}
                      borderWidth="1px"
                      borderColor="gray.300"
                      _hover={{
                        borderWidth: "1px",
                        borderColor: "gray.300",
                      }}
                      color="black"
                    >
                      {sessionLocationOptions.map((option) => (
                        <option
                          style={{ backgroundColor: "white", color: "black" }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
              </FormControl>
              {/* <FormControl mt="4" isInvalid={errors.sessionRate}>
            <FormLabel color="black">Specify the rate</FormLabel>
              <Controller
                control={control}
                name="sessionRate"
                render={({ field }) => (
                  <Input
                    type="number"
                    {...field}
                    borderWidth="1px"
                    borderColor="gray.300"
                    color="black"
                    placeholder="Specify the rate"
                    _placeholder={{
                      color: "black"
                    }}
                    _hover={{
                      borderWidth: "1px",
                      borderColor: "gray.300",
                    }}
                  >
                  </Input>
                )}
              />
              <FormErrorMessage>{errors.sessionRate?.message} </FormErrorMessage>
            </FormControl> */}

              {!requestId ? (
                <Button
                  type="submit"
                  bg="black"
                  color="white"
                  fontWeight="bold"
                  rounded="20px"
                  w="full"
                  mt="8"
                  _hover={{
                    border: "1px solid black",
                    color: "black",
                    bg: "white",
                  }}
                >
                  Send Request
                </Button>
              ) : (
                <>
                  <Button
                    bg="black"
                    color="white"
                    fontWeight="bold"
                    rounded="20px"
                    w="full"
                    mt="8"
                    _hover={{
                      border: "1px solid black",
                      color: "black",
                      bg: "white",
                    }}
                  >
                    Send New Request
                  </Button>
                  <Button
                    type="button"
                    bg="red.500"
                    color="white"
                    fontWeight="bold"
                    rounded="20px"
                    w="full"
                    mt="4"
                    _hover={{
                      border: "1px solid #E53E3E",
                      color: "red.500",
                      bg: "white",
                    }}
                  >
                    Delete Request
                  </Button>
                </>
              )}


              <Button
                bg="#E1526C"
                color="white"
                fontWeight="bold"
                rounded="20px"
                w="full"
                mt="4"
                onClick={() => {
                  handleClose();
                }}
                _hover={{
                  color: "white",
                  bg: "#E1526C",
                }}
              >
                Cancel
              </Button>
            </form>
          </ModalContent>
        </Modal>
      </Box>

       {/* Login Modal */}
       <Modal
        isOpen={isLoginForm}
        onClose={() => setLoginForm(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg={"#fff"} color="#000">
          <ModalHeader>
            <Heading size="md" >Authentication Required</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Oops, it seems like you are not authenticated. Please authenticate yourself to complete the session request.
            </Text>
            <Flex direction="row" align="center" justify="space-between" px="5" mb="3">
                <Button
                  as={Link}
                  to={`/login`}
                  bg="selectbg"
                  _hover={{
                    bg: "white",
                    color: "black",
                    border: "1px solid black",
                  }}
                  border="1px solid #f2f2f2"
                  backgroundColor="black"
                  rounded="40px"
                  py="20px"
                  px="40px"
                  fontSize="14px"
                  fontWeight="600">
                  Login
                </Button>
                <Button
                  as={Link}
                  to={`/register`}
                  bg="selectbg"
                  _hover={{
                    bg: "white",
                    color: "black",
                    border: "1px solid black",
                  }}
                  border="1px solid #f2f2f2"
                  backgroundColor="black"
                  rounded="40px"
                  py="20px"
                  px="40px"
                  fontSize="14px"
                  fontWeight="600">
                  Register
                </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestForm;
