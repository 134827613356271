import React, { useState, useEffect } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  isSaturday,
  isSunday,
} from "date-fns";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { enGB } from "date-fns/locale";
import CustomToolbar from "../CustomToolbar";
import CustomDayHeader from "../weekview_header";
import EventModal from "../EventModal";
import "../calender.css";
import { Box, Tooltip, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, Button, ModalFooter, useDisclosure } from "@chakra-ui/react";
import CalenderHeader from "../../Headers/CalenderHeader";
import { API_BASE_URL } from "../../../apiPaths";
import RequestForm from "../../Forms/MyRequest/RequestForm";
const locales = {
  "en-GB": enGB,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { locale: enGB }),
  getDay,
  locales,
});

const StdCalendar = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { tutorId } = useParams();
  const location = useLocation();
  const [view, setView] = useState(() => {
    return localStorage.getItem("view") || "month";
  });
  const [tutor, setTutor] = useState({});
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDateStart, setSelectedDateStart] = useState(new Date);
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date);
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const sessionRequestFormData = JSON.parse(localStorage.getItem('sessionRequestFormData'));
  const [cancelBookingSession, setCancelBookingSession] = useState(false);
  const handleViewChange = (view) => {
    localStorage.setItem("view", view);
    setView(view);
  };

  const formatTime = (dateString) => {
    return format(new Date(dateString), 'HH:mm');
  };

  const searchParams = new URLSearchParams(location.search);
  const tid = searchParams.get("tid");

  // Custom day cell style getter
  // const dayPropGetter = (date) => {
    // const dayOfWeek = getDay(date);
    // const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    // if (isWeekend) {
    //   return {
    //     style: {
    //       backgroundColor: "#f0f0f0",
    //     },
    //   };
    // }
    // return {};
  // };

  const dayPropGetter = (date) => {
    const today = new Date();
    const isPast = date < today;

    if (date.toDateString() === today.toDateString()) {
      return {};
    } else if (isPast) {
      return {
        style: {
          backgroundColor: '#f0f0f0'
        }
      };
    }
  };

  const eventPropGetter = (event) => {
    const isNarrowScreen = window.innerWidth <= 600;
    let newStyle = {
      backgroundColor: "lightgrey",
      color: "black",
      borderRadius: isNarrowScreen ? "20px" : "5px",
      display: "flex",
      alignItems: "center",
      padding: "5px 10px",
      border: "2px solid black",
      color: "white",
    };

    if (event.isBooked === "y") {
      const today = new Date();
      if (new Date(event.end) < today) newStyle.background = "linear-gradient(90deg, maroon, darkred 25%, brown 50%, darkred 75%, maroon)";
      else newStyle.background = "linear-gradient(90deg, darkred, red 25%, lightcoral 50%, red 75%, darkred)";
    } else {
      newStyle.background = "linear-gradient(90deg, darkgreen, green 25%, lightgreen 50%, green 75%, darkgreen)";
    }

    return {
      style: newStyle,
    };
  };

  const handleCloseModal = (val) => {
    setShowModal(val);
  };

  function formatEventDate(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Options for formatting
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: '2-digit' };
    const formattedDate = startDate.toLocaleDateString('en-GB', options); // Format: Tue 10 Oct 2023
    const startTime = startDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // Format: 10:00
    const endTime = endDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }); // Format: 11:00

    return `${formattedDate}, ${startTime} - ${endTime}`;
}

  const handleEventSelect = (event) => {
    const formattedString = formatEventDate(event.start, event.end);
    if(event?.isBooked == "y") {
      // setCancelBookingSession(event.serviceId?.name)
      // onOpen();
      return;
    }
    if (event?.serviceId?.name === "One to One Session")
      navigate(`/tutor/choose-service/${event.userId}/${formattedString}/${event._id}`);
    else navigate(`/book-session/${event?.serviceId?._id}?type=n&session=${event?._id}`);
  };

  // setSelectedEvent(null);

  //   const selectedDateStart = new Date(slotInfo.start);
  //   const startOfWorkday = new Date(selectedDateStart.setHours(9, 0, 0, 0));
  //   const endOfWorkday = new Date(selectedDateStart.setHours(17, 0, 0, 0));

  //   setSelectedSlot({ start: startOfWorkday, end: endOfWorkday });
  //   setShowModal(true);

  // const handleSlotSelect = (slotInfo) => {
  //   const selectedDateStart = new Date(slotInfo.start);
  //   setSelectedDateStart(selectedDateStart);
  //   const today = new Date();
  //   const isPast = selectedDateStart < today;
  //   if(isPast) return;
  //   setShowModal(true);
  // };
  const handleSlotSelect = (slotInfo) => {
    const startDate = new Date(slotInfo.start);
  
    // Adjust for timezone offset
    const offset = startDate.getTimezoneOffset();
    const localStartDate = new Date(startDate.getTime() - offset * 60 * 1000);
  
    const formattedStartDate = localStartDate.toISOString().substr(0, 16);
  
    const endDate = new Date(localStartDate.getTime() + 2 * 60 * 60 * 1000);
    const formattedEndDate = endDate.toISOString().substr(0, 16);

    setSelectedDateStart(formattedStartDate)
    setSelectedDateEnd(formattedEndDate)
    const today = new Date();
    const isPast = localStartDate < today;
    if (isPast) return;
    setShowModal(true);
  };

  useEffect(() => {
    if (sessionRequestFormData && sessionRequestFormData?.isRequestModelOpen) {
      setShowModal(true)
    }
    async function fetchData() {
      const tutorID = tutorId ? tutorId : tid;
      
      try {
        const response = await fetch(`${API_BASE_URL}/session/${tutorID}`, {
          // headers: {
          //   "x-access-token": currentUser.token,
          // },
        });
        const sessions = await response.json();
        const eventsWithDates = sessions.data.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }));
        setEvents([events, ...eventsWithDates]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

   async function getTutor() {
      const response = await fetch(`${API_BASE_URL}/user/${tutorId}`, {
        headers: {
          'x-access-token': currentUser.token 
        }
      });
      const user = await response.json();
      setTutor(user.data)
      console.log(user);
      
   }

    fetchData();
    getTutor();
  }, []);

  const CustomEvent = ({ event }) => {
    return (
      <Box marginLeft={{ base: "0px", xl: "0px" }}>
        <Box
          display="block"
          fontSize="14px"
          fontStyle="normal"
          textAlign="left"
        >
           <Box fontWeight="800">
           {view === 'month' 
              ? (event?.serviceId?.name?.toLowerCase() === 'one to one session'
                  ? 
                    <>
                      {`${formatTime(event?.start)} ${event?.totalTime}`}
                      <Box display="block">{event?.bookedBy?.displayName}</Box>
                    </>
                  : (
                      <>
                        {`${formatTime(event?.start)} ${event?.totalTime}`}
                        <Box>{event?.serviceId?.name}</Box>
                        <Box display="block">{event?.bookedBy?.displayName}</Box>
                      </>
                    )
                )
              : (event?.serviceId?.name?.toLowerCase() === 'one to one session') 
                  ? '' 
                  : <>
                      {`${formatTime(event?.start)} ${event?.totalTime}`}
                      <Box display="block">{event?.serviceId?.name}</Box>
                      <Box display="block">{event?.bookedBy?.displayName}</Box>
                    </>
            }
            </Box>
        </Box>
      </Box>
    );
  };

  ///Day max and min start times (from tutor settings)
  const dayStart = new Date().setHours(8, 0, 0, 0);
  const dayEnd = new Date().setHours(22, 0, 0, 0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <CalenderHeader />
      <Box bg="white" rounded={"10px"}>
        <Calendar
          localizer={localizer}
          // min={dayStart} // Set the minimum time for the calendar view
          // max={dayEnd} // Set the maximum time for the calendar view
          events={events}
          dayPropGetter={dayPropGetter}
          eventPropGetter={eventPropGetter}
          startAccessor={(event) => {
            return new Date(event.start);
          }}
          endAccessor={(event) => {
            return new Date(event.end);
          }}
          view={view}
          style={{ height: `${window.innerHeight}px` }}
          views={["month", "week", "day"]}
          onView={handleViewChange}
          onSelectSlot={handleSlotSelect}
          onSelectEvent={handleEventSelect}
          selectable={true}
          components={{
            toolbar: (props) => <CustomToolbar {...props} view={view} profile={tutor.profilePic} displayName={tutor.displayName} />,
            event: CustomEvent,
            week: {
              header: CustomDayHeader,
            },
          }}
        />
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent
          bg="white"
          color="black"
          px={{ base: "15px", lg: "40px" }}>
          <ModalHeader textAlign="center">Cancel { cancelBookingSession }?</ModalHeader>
          <ModalFooter display="flex " flexDir="column" w="full" gap="3">
            <Button
              w="full"
              onClick={onClose}
              color="white"
              _hover={{
                bg: "deletecolor",
              }}
              bg="deletecolor"
              rounded="40px"
              py="12px"
              px="40px"
              fontSize="14px"
              fontWeight="600">
              Cancel booking
            </Button>
            <Button
              onClick={onClose}
              w="full"
              variant="ghost"
              color="black"
              _hover={{
                bg: "selectbg",
              }}
              bg="selectbg"
              rounded="40px"
              py="12px"
              px="40px"
              fontSize="14px"
              fontWeight="600">
              Go back
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {showModal && (
        <RequestForm onFormClose={handleCloseModal} isCalenderRequest={true} startDate={selectedDateStart} endDate={selectedDateEnd} tutorId={tutorId} />
      )}
    </>
  );
};

export default StdCalendar;
