import React, { useState, useEffect } from "react";
import MyBookingHeader from "../../../Headers/MyBookingHeader";
import MyBookingList from "./MyBookingList";
import { Flex } from "@chakra-ui/react";
import { API_BASE_URL } from "../../../../apiPaths";
import MyBookingsTutorList from "../MyBookings/MyBookingList";

export default function MyBookings() {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${API_BASE_URL}/booking/mybookings/${currentUser?.id}`,
          {
            headers: {
              "x-access-token": currentUser.token,
            },
          }
        );
        const res = await response.json();
        setBookings(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  return (
    <>
      <MyBookingHeader
        title="My Bookings"
        des="View and manage your upcoming bookings"
      />
      <Flex
        flexDirection="column"
        mt={{ base: "20px", md: "40px" }}
        gap="24px"
        maxW={{ base: "90%", md: "55%" }}
        mx="auto">
        {bookings?.map((booking) => (
          <MyBookingsTutorList data={booking} key={booking._id} />
        ))}
      </Flex>
    </>
  );
}

// export default function UpComingSessions() {

//   const currentUser = JSON.parse(localStorage.getItem("currentUser"));
//   const [bookings, setBookings] = useState(null);

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         const response = await fetch(`${API_BASE_URL}/session/upcoming-sessions/${currentUser.id}`, {
//           headers: {
//             'x-access-token': currentUser.token
//           }
//         });
//         const sessions = await response.json();
//         setBookings(sessions.data[0].data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     }

//     fetchData();
//   }, []);

//   return (
//     <>
//       <MyBookingHeader />
//       <Flex
//         flexDirection="column"
//         mt={{ base: "20px", md: "40px" }}
//         gap="24px"
//         maxW={{ base: "90%", md: "55%" }}
//         mx="auto"
//       >
//       {bookings?.map(session => (
//         <UpcomingSessionList key={session._id} data={session}/>
//       ))}
//       </Flex>
//     </>
//   );
// }
