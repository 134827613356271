import React, { useEffect } from "react";
import { useState, useRef } from "react";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Heading,
  VStack,
  Center,
  InputGroup,
  Box,
  Textarea,
  FormLabel,
  Image,
  Flex,
  Select,
  FormErrorMessage,
  Text,
  useToast,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody
} from "@chakra-ui/react";

import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_BASE_URL, USER_IMAGE_URL } from '../apiPaths';

const signupSchema = yup.object().shape({});
const Profile = () => {
  const cancelRef = useRef();
  const toast = useToast();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const [user, setUser] = useState({});
  const [profile, setprofile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const formData = new FormData();
  const navigate = useNavigate();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(signupSchema),
    defaultValues: {},
  });

  const watchDisplayName = watch("displayName", "");
  const watchFirstName = watch("firstName", "");
  const watchLastName = watch("lastName", "");
  const watchContactNumber = watch("contactNumber", "");
  const watchDescription = watch("description", "");
  const profilePicFile = watch("profile_pic");

  const profilePicPreviewUrl = profilePicFile?.[0]
    ? URL.createObjectURL(profilePicFile[0])
    : profile;

  
    async function fetchData() {
      try {
        const response = await fetch(`${API_BASE_URL}/user/${currentUser?.id}`, {
          headers: {
            'x-access-token': currentUser.token 
          }
        });
        const user = await response.json();
        setUser(user.data);
        setprofile(user.data.profilePic)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

  useEffect(() => {
    fetchData();
  }, []);

  const deactivateUser = async () => {
    setIsConfirmModalOpen(true);
  };

  const confirmDeactivateUser = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/${currentUser.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': currentUser.token 
        }
      });

      if (!response.ok) {
        toast({
          title: "Error deactivating account.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error('Network response was not ok');
      } else {
        toast({
          title: "Account deactivated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/login");
      }
    } catch (error) {
      console.error('Error deactivating account:', error);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    
    const mergedData = {
      displayName: data.displayName || user?.displayName || "",
      firstName: data.firstName || user?.firstName || "",
      lastName: data.lastName || user?.lastName || "",
      contactNumber: data.contactNumber || user?.contactNumber || "",
      description: data.description || user?.description || "",
      profile_pic: data?.profile_pic[0] || "",
    };
  
    formData.append("firstName", mergedData.firstName);
    formData.append("lastName", mergedData.lastName);
    formData.append("contactNumber", mergedData.contactNumber);
    formData.append("displayName", mergedData.displayName);
    formData.append("profilePic", mergedData.profile_pic);
    formData.append("description", mergedData.description);

    try {
      const response = await fetch(`${API_BASE_URL}/user/${currentUser.id}`, {
        method: 'PUT',
        headers: {
          'x-access-token': currentUser.token 
        },
        body: formData
      });

      if (!response.ok) {
        toast({
          title: "Updating failed.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        throw new Error('Network response was not ok');
      } else {
        toast({
          title: "Data updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchData();
      }
    } catch (error) {
      console.error('There has been a problem with your operation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Center mt={{ base: "20px", xl: "40px" }}>
        <Stack
          minW={{ base: "100%", md: "640px" }}
          spacing={{ base: 6, xl: 12 }}
          px={{ base: "15px", xl: "0px" }}
        >
          <VStack
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            bg="#ffffff"
            color="black"
            position="relative"
            borderTopRadius={{ base: "16px", md: "none" }}
            rounded={{ base: "sm", md: "lg" }}
            px={{ base: "15px", md: "50px" }}
            py={{ base: "40px", md: "32px" }}
          >
            <VStack spacing={{ base: "15px", md: "20px" }} w="100%">
            <FormControl>
                <Box
                  as="label"
                  htmlFor="profile_pic_input"
                  w="100%"
                  display="inline-block"
                  rounded="md"
                  cursor="pointer"
                >
                  {profilePicPreviewUrl ? (
                    <Center
                      w="100%"
                      flexDirection="column"
                      gap="16px"
                      fontSize="14px"
                      alignItems="center"
                      py={{ base: "24px", md: "24px" }}
                    >
                      <Image
                        rounded="full"
                        objectFit="cover"
                        src={USER_IMAGE_URL + profilePicPreviewUrl}
                        alt="Profile Pic"
                        style={{
                          width: "80px",
                          height: "80px",
                          display: "block",
                        }}
                        crossOrigin="annonymous"
                      />
                      <Box color="#E1526C">Remove</Box>
                    </Center>
                  ) : (
                    // Your SVG placeholder component
                    <Center py={{ base: "24px", md: "24px" }} color="white">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="80"
                            height="81"
                            viewBox="0 0 80 81"
                            fill="none"
                          >
                            <rect
                              y="0.5"
                              width="80"
                              height="80"
                              rx="40"
                              fill="#616161"
                            />
                            <rect
                              x="41.0469"
                              y="28.0459"
                              width="24"
                              height="3"
                              transform="rotate(90 41.0469 28.0459)"
                              fill="black"
                            />
                            <rect
                              x="27.5469"
                              y="38.5459"
                              width="24"
                              height="3"
                              fill="black"
                            />
                          </svg>
                        </Box>
                        <Box color="#969696" mt="3">
                          Add a profile picture
                        </Box>
                      </Box>
                    </Center>
                  )}
                </Box>
                <Input
                  id="profile_pic_input"
                  type="file"
                  style={{ display: "none" }}
                  {...register("profile_pic")}
                />
                {errors.profile_pic && (
                  <Box color="#E1526C" fontSize="14px" mt="2">
                    <p>{errors.profile_pic.message}</p>
                  </Box>
                )}
              </FormControl>
              
              <FormControl id="displayName">
                <FormLabel fontSize="14px">Display Name</FormLabel>
                <Input
                  borderColor={errors.displayName ? "#E1526C" : "#616161"}
                  rounded="md"
                  defaultValue={user?.displayName}
                  _hover={{
                    borderColor: "#616161",
                  }}
                  color="black"
                  type="text"
                  placeholder="DJ Ads"
                  {...register("displayName")}
                  _placeholder={{
                    color: "#D2D2D2",
                    fontSize: { base: "12px", md: "14px" },
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.displayName && <p>{errors.displayName.message}</p>}
                </Box>
              </FormControl>

              <FormControl id="firstName">
                <FormLabel fontSize="14px">First Name</FormLabel>
                <Input
                  borderColor={errors.firstName ? "#E1526C" : "#616161"}
                  rounded="md"
                  defaultValue={user?.firstName}
                  _hover={{
                    borderColor: "#616161",
                  }}
                  color=" black"
                  type="text"
                  placeholder="First Name"
                  {...register("firstName")}
                  _placeholder={{
                    color: "#D2D2D2",
                    fontSize: { base: "12px", md: "14px" },
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.firstName && <p>{errors.firstName.message}</p>}
                </Box>
              </FormControl>

              <FormControl id="lastName">
                <FormLabel fontSize="14px">Last Name</FormLabel>
                <Input
                  borderColor={errors.lastName ? "#E1526C" : "#616161"}
                  rounded="md"
                  defaultValue={user?.lastName}
                  _hover={{
                    borderColor: "#616161",
                  }}
                  color=" black"
                  type="text"
                  placeholder="Last Name"
                  {...register("lastName")}
                  _placeholder={{
                    color: "#D2D2D2",
                    fontSize: { base: "12px", md: "14px" },
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.lastName && <p>{errors.lastName.message}</p>}
                </Box>
              </FormControl>

              <FormControl id="contactNumber">
                <FormLabel fontSize="14px">Contact Number</FormLabel>
                <Input
                  borderColor={errors.contactNumber ? "#E1526C" : "#616161"}
                  rounded="md"
                  defaultValue={user?.contactNumber}
                  _hover={{
                    borderColor: "#616161",
                  }}
                  color=" black"
                  type="text"
                  placeholder="+42 XXXXXXXXXX"
                  {...register("contactNumber")}
                  _placeholder={{
                    color: "#D2D2D2",
                    fontSize: { base: "12px", md: "14px" },
                  }}
                />
                <Box color="#E1526C" fontSize="14px" mt="5px">
                  {errors.contactNumber && <p>{errors.contactNumber.message}</p>}
                </Box>
              </FormControl>
           
              <FormControl>
                <FormLabel fontSize="14px">Description</FormLabel>
                <Textarea
                      defaultValue={user?.description}
                      borderColor={errors.description ? "#E1526C" : "border"}
                      rounded="md"
                      _hover={{
                        borderColor: "#616161",
                      }}
                      type="text"
                      {...register("description")}
                      placeholder="Description"
                      _placeholder={{
                        color: "text",
                        fontSize: { base: "12px", md: "14px" },
                      }}
                    />

                <FormErrorMessage color="#E1526C" fontSize="14px" mt="5px">
                  {errors.description && <p>{errors.description?.message}</p>}
                </FormErrorMessage>
              </FormControl>
            </VStack>

            <VStack w="100%" mt={{ base: "15px", md: "30px" }}>
              <Button
                color="white"
                rounded="40px"
                w="100%"
                bg={"grey"}
                _hover={{
                  bg: "black"
                }}
                py="12px"
                fontSize="14px"
                fontWeight="600"
                type="submit"
                isLoading={isLoading}
              >
                Save
              </Button>
              
            </VStack>
            <VStack w="100%" mt={{ base: "15px" }}>
            <Button
                color="white"
                rounded="40px"
                w="100%"
                bg={"grey"}
                _hover={{
                  bg: "black",
                }}
                py="12px"
                fontSize="14px"
                fontWeight="600"
                onClick={deactivateUser}
              >
                Deactivate Account
              </Button>
              <AlertDialog
                isOpen={isConfirmModalOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsConfirmModalOpen(false)}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Deactivate Account
                    </AlertDialogHeader>
                    <AlertDialogBody>
                      Are you sure you want to deactivate your account? This action cannot be undone.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={() => setIsConfirmModalOpen(false)}>
                        Cancel
                      </Button>
                      <Button colorScheme="red" ml={3} onClick={confirmDeactivateUser}>
                        Deactivate
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
              
            </VStack>
          </VStack>
        </Stack>
      </Center>
    </Box>
  );
};

export default Profile;
